import { useSelector } from 'react-redux';
import { RootState } from 'store';
import CreateBtn from './ButtonDiv/CreateBtn';
import { EditToolOptionsDiv, MainDiv, ToolBarWrapper } from './style';
import CalculateButton from './ButtonDiv/CalculateBtn';
import DrawBtn from './ButtonDiv/DrawBtn';
import EditToolOptions from './ButtonDiv/EditToolOptions';
import UndoButton from './ButtonDiv/UndoButton';

export default function EditorToolBar() {
	const { showIrradiance } = useSelector((state: RootState) => state.Irradiance.data);
	const { editModeEnabled, deletedRoofIndexes, newlyCreatedFacets, drawModeEnabled } = 
	useSelector((state: RootState) => state.toolNewPostions.data);
	const hasModified = !!deletedRoofIndexes?.length || !!newlyCreatedFacets.length;
	const showFinalizeBtn = editModeEnabled && (hasModified);
	
	if(showIrradiance){
		return null;
	}

	return (
		<MainDiv>
			<ToolBarWrapper>
				<EditToolOptionsDiv>
					<DrawBtn />
					<EditToolOptions/>
					{drawModeEnabled && <UndoButton/>}
				</EditToolOptionsDiv>
				{ showFinalizeBtn && <CreateBtn /> }
				<CalculateButton />
			</ToolBarWrapper>
		</MainDiv>
	);
}