import { Middleware } from '@reduxjs/toolkit';
import { changePanel } from '../roofDataSlice';
import { PARENT_EVENTS } from '../../../../constants/index';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const postMessageMiddleware: Middleware = (store) => (next) => async(action) => {
	if (action.type === changePanel.fulfilled.type) {
		console.log('unsync event called');
		window.parent.postMessage({ id: PARENT_EVENTS.DESIGN_UNSYNCED }, '*');
	}
	return next(action);
};

export default postMessageMiddleware;
