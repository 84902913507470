import React, { useEffect, useRef } from 'react';
import { useRefDispatch, useRefs } from 'contexts/RefContext';
import RasterImage from './RasterImage';
import { Group } from 'react-konva';
import { Circle } from 'konva/lib/shapes/Circle';
import Konva from 'konva';
import { Vector2d } from 'konva/lib/types';
import { setNewlyCreatedFacets, setUnfinalizedFacet } from 'store/slices/ToolSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { KonvaEventObject } from 'konva/lib/Node';
import { setDropdownState } from 'store/slices/EditDeleteOptionsModalSlice';

const distance = (a: number[], b: number[]) => Math.sqrt((a[0] - b[0]) ** 2 + (a[1] - b[1]) ** 2);
function PanelDrawer() {
	const { editModeEnabled, drawModeEnabled, moveStage } = useSelector((state: RootState) => state.toolNewPostions.data);
	const { konvaRef } = useRefs();
	const refDispatch = useRefDispatch();
	const groupRef = useRef<Konva.Group | null>(null);
	const pointsRef = useRef<number[][]>([]);
	const lineRef = useRef<Konva.Line>(new Konva.Line({
		stroke: 'rgba(0,0,0,0.4)',
		strokeWidth: 3,
		fill: 'rgba(0,0,0,0.4)',
	}));
	const pointLineRef = useRef<Konva.Line>(new Konva.Line({
		stroke: 'rgba(0,0,0,0.4)',
		strokeWidth: 3,
		fill: 'rgba(0,0,0,0.4)',
	}));
	const isDrawing = useRef<boolean>(false);
	const longTouchTimerRef = useRef<NodeJS.Timeout | undefined>(undefined);
	const dispatch = useDispatch<AppDispatch>();
	const canDrawFacet = editModeEnabled && drawModeEnabled && !moveStage;

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if ((event.ctrlKey && event.key === 'z') || event.key === 'Escape') {
				deleteLastLine();
			}
		};
		document.addEventListener('keydown', handleKeyDown);
		return () => document.removeEventListener('keydown', handleKeyDown);
	}, []);

	useEffect(() => {
		refDispatch({ type: 'ADD_LAST_POLYGON_REF', payload: { ref: {
			groupRef:null,
			pointsRef:null,
			lineRef:null,
			pointLineRef:null,
		}} });
	}, [drawModeEnabled]);

	function deleteLastLine() {
		if (!pointsRef.current.length) return;

		const children = groupRef.current?.children;
		pointLineRef.current.points([]);

		if (children && children.length > 1) {
			const lastChild = children[children.length - 1];
			if (lastChild instanceof Konva.Image || lastChild instanceof Konva.Line) return;
			lastChild.destroy();
		}

		const points = lineRef.current.points();
		if (points.length >= 2) {
			points.splice(-2);
			lineRef.current.points(points);
		}
		if (pointsRef.current.length > 0) {
			pointsRef.current.splice(-1);
		}
	}

	function dispatchNewlyCreatedFacets(facetPoints: number[][]) {
		dispatch(setNewlyCreatedFacets({
			newlyCreatedFacet: {
				hullCoords: facetPoints,
				konvaPolygonID: lineRef.current._id
			}
		}));
	}

	function makeOtherPolygonsInvisbleWhileUserIsDrawing(opacity: number) {
		groupRef.current?.children?.forEach(c => {
			if (c instanceof Konva.Image) return;
			c.opacity(opacity);
		});
	}

	function makePolygon() {
		if (!konvaRef?.current) return;
		const {x, y} = konvaRef.current.getRelativePointerPosition() as Vector2d;

		if (groupRef.current?.children?.length === 1) {
			pointsRef.current = [];
			isDrawing.current = false;
			pointLineRef.current= new Konva.Line({
				stroke: 'rgba(0,0,0,0.4)',
				strokeWidth: 3,
				fill: 'rgba(0,0,0,0.4)',
			});
		}
		if (pointsRef.current.length === 0 && !isDrawing.current) {
			makeOtherPolygonsInvisbleWhileUserIsDrawing(0.2);
			groupRef.current?.add(lineRef.current);
			groupRef.current?.add(pointLineRef.current);
		}
		if (pointsRef.current.length > 2 && distance([x, y], pointsRef.current[0]) < 10) {
			lineRef.current.closed(true);
			pointLineRef.current.remove();
			const pts = lineRef.current?.points();

			const p: number[][] = [];
			pts.forEach((v, i) => {
				const ind = Math.floor(i / 2);
				if (i % 2 === 0) p.push([v]);
				else p[ind].push(v);
			});
			dispatchNewlyCreatedFacets(p);
			pointsRef.current = [];
			lineRef.current = new Konva.Line({
				stroke: 'rgba(0,0,0,0.4)',
				strokeWidth: 3,
				fill: 'rgba(0,0,0,0.4)',
			});
			pointLineRef.current= new Konva.Line({
				stroke: 'rgba(0,0,0,0.4)',
				strokeWidth: 3,
				fill: 'rgba(0,0,0,0.4)',
			});
			isDrawing.current = false;
			makeOtherPolygonsInvisbleWhileUserIsDrawing(1);
			return;
		}

		pointsRef.current.push([+x, +y]);
		addPoint(x, y);
		lineRef.current.points(pointsRef.current.flat());
		lineRef.current.on('contextmenu', handlePolygonClick);
		lineRef.current.on('touchstart', handleTouchStart);
		lineRef.current.on('touchend', handleTouchEndOrCancel);
		refDispatch({ type: 'ADD_LAST_POLYGON_REF', payload: { ref: {
			groupRef,
			pointsRef,
			lineRef,
			pointLineRef,
		}} });

	}

	function addPoint(x: number, y: number) {
		groupRef.current?.add(new Circle({
			x: x,
			y: y,
			radius: 3,
			fill: 'blue',
			stroke: 'black',
			strokeWidth: 1
		}));
		isDrawing.current = true;
	}

	function handleMouseMove() {
		if (!editModeEnabled || !drawModeEnabled || !konvaRef?.current || !pointsRef.current.length) return;
		const {x, y} = konvaRef.current.getRelativePointerPosition() as Vector2d;
		const points = pointsRef.current;
		const [startX, starty] = points[points.length - 1];
		pointLineRef.current.points([startX, starty, x, y]);
	}

	function handlePolygonClick(e: KonvaEventObject<MouseEvent>) {
		if (!editModeEnabled || !drawModeEnabled) return;
		e.evt.preventDefault();
		dispatch(setUnfinalizedFacet({ id: e.target._id }));
		dispatch(setDropdownState({ open: true, position: { x: e.evt.clientX, y: e.evt.clientY } }));
	}

	function handleTouchStart(e: KonvaEventObject<TouchEvent>) {
		if (!editModeEnabled || !drawModeEnabled) return;
		clearTimeout(longTouchTimerRef.current);
		longTouchTimerRef.current = setTimeout(() => {
			dispatch(setUnfinalizedFacet({ id: e.target._id }));
			dispatch(setDropdownState({ open: true, position: { x: e.evt.touches[0].clientX, y: e.evt.touches[0].clientY } }));
		}, 2000);
	}

	function handleTouchEndOrCancel() {
		clearTimeout(longTouchTimerRef.current);
	}

	return (
		<Group
			ref={groupRef}
			name={'panel-drawer'}
			onClick={(e) => {
				if(!canDrawFacet ) return;
				if (e.evt.button === 0) {
					makePolygon();
				}
			}}
			onMouseMove={handleMouseMove}
		>
			<RasterImage />
		</Group>
	);
}

export default PanelDrawer;